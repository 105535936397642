@import '../../styles/shared.scss';

.container {
    width: 100vw;
    height: 100vh;
    // min-height: 100vh;
    background-color: $purple-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px 0 19px;

    &__header {
        display: flex;
        width: 382px;
        align-items: center;
        min-height: 48px;
    }

    &__avatar {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 48px;
        height: 48px;
        min-width: 48px;
        border-radius: 100px;
        border: 2px rgba(125, 74, 251, 0.10) solid;
        margin-right: 16px;
        overflow: hidden;

        &>div {
            width: 100%;
            height: 100%;

            display: flex;
            justify-content: center;
            align-items: center;

            background-color: $purple;
            @include text_medium_bold;

            color: $white
        }

        &>img {
            width: 100%;
            height: 100%;
            object-fit: cover
        }
    }

    &__name {
        @include heading_2;
        color: $white;
    }

    &__location {
        @include text_medium_regular;
        display: inline;
        color: $white;
        margin-left: 8px;
    }

    &__content {
        flex: 1;
        width: 382px;
        margin: 24px 0 32px;
        border: 4px solid rgba(125, 74, 251, 0.10);
        border-radius: 12px;
        overflow: hidden;
        // display: flex;
        // justify-content: center;
        // align-items: center;
        position: relative;
        cursor: pointer;

        &--error {
            @include heading_2;
            text-align: center;
            color: $white;
        }

        &--centering{
            display: flex;
            height: 100%;
            flex: 1;
            justify-content: center;
            align-items: center;
        }
    }

    &__footer {
        width: 539px;
        padding: 16px;
        border-radius: 12px;
        background-color: $purple_01;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        &--powered-by {
            @include text_medium_bold;
            color: $white;
        }

        &--subtitle {
            @include text_large_bold;
            color: $white
        }
    }

    &__powered--text {
        @include text_medium_bold;
        color: $white;
    }

    &__logo {
        width: 142px;
        height: 28px;
        margin: 8px 0;
    }

    &__try-button {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        width: 134px;
        height: 48px;
        border-radius: 15px;
        background: linear-gradient(78deg, #8826FF 0%, #42FFE8 100%);

        @include text_large_bold;
        color: $white;

        &:active {
            background: linear-gradient(78deg, #a459ff 0%, #8ffff2 100%);
        }
    }

    @media (max-width: 600px) {
        padding: 30px 20px 19px;

        &__header {
            width: 100%;
        }

        &__location {
            display: block;
            margin: 0;
        }

        &__footer {
            width: 100%;

            &--powered-by {
                @include text_small_bold;
                color: $white
            }

            &--subtitle {
                @include text_medium_bold;
                color: $white
            }
        }

        &__content {
            width: 100%;
        }

        &__try-button {
            width: 115px;
            min-width: 115px;
            height: 36px;
            border-radius: 12px;
            margin-left: 12px;

            @include text_medium_bold;
            color: $white
        }
    }
}