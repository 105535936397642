@import '../../styles/shared.scss';


.error-page{
    width: 100vw;
    height: 100vh;
    background-color: $purple-dark;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;

    // @include heading_1;
    font-family: $primary-font;
    font-weight: 700;
    font-size: 60px;
    color: $white;


}